<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md10>
      <material-card
        class="elevation-12 rounded-card"
        :title="title"
        icon="mdi-office-building"
      >
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">ID:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="5">
                  <v-text-field
                    solo
                    class="right-input"
                    v-model.trim="form.id"
                    :error-messages="idErrors"
                    maxlength="40"
                    required
                    @input="$v.form.id.$touch()"
                    @blur="$v.form.id.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="">
                <v-col cols="12" xs="12" md="2">
                  <v-subheader class="label-left">Name/Nombre:</v-subheader>
                </v-col>
                <v-col cols="12" xs="12" md="10">
                  <v-text-field
                    solo
                    class="right-input"
                    v-model.trim="form.name"
                    :error-messages="nameErrors"
                    maxlength="40"
                    required
                    @input="$v.form.name.$touch()"
                    @blur="$v.form.name.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="">
                <v-col cols="12" xs="12" md="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        disable-icon-rotate
                        icon="mdi-truck-delivery-outline"
                      >
                        <template v-slot:default="{ open }">
                          <v-row no-gutters>
                            <v-col cols="4">
                              <template>
                                <v-icon color="success">
                                  mdi-truck-delivery-outline
                                </v-icon>
                              </template>
                              INBOUND
                            </v-col>
                            <v-col cols="8" class="text--secondary">
                              <v-fade-transition leave-absolute>
                                <span v-if="open" key="0">
                                  Additional data
                                </span>
                                <span v-else key="1">
                                  {{ trip.name }}
                                </span>
                              </v-fade-transition>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="">
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Pallets descargados:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbUnloadedPallets"
                            ></decimal-input>
                          </v-col>
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Pallets restack:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbPalletsRestack"
                            ></decimal-input>
                          </v-col>
                        </v-row>

                        <v-row class="">
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >QC Inspection:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbQcInspection"
                            ></decimal-input>
                          </v-col>
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Cajas dañadas:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbDamagedBoxes"
                            ></decimal-input>
                          </v-col>
                        </v-row>
                        <v-row class="">
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Tarimas dañadas:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbDamagedPallets"
                            ></decimal-input>
                          </v-col>
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Pallets In:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbPalletsIn"
                            ></decimal-input>
                          </v-col>
                        </v-row>
                        <v-row class="">
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left">PTI:</v-subheader>
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbPTI"
                            ></decimal-input>
                          </v-col>
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Repack In:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbRepackIn"
                            ></decimal-input>
                          </v-col>
                        </v-row>
                        <v-row class="">
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Pallet Replacement:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbPalletReplacement"
                            ></decimal-input>
                          </v-col>
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Overtime:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbOvertime"
                            ></decimal-input>
                          </v-col>
                        </v-row>
                        <v-row class="">
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Sunday OT:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbSundayOt"
                            ></decimal-input>
                          </v-col>
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Cucumber Repack:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbCucumberRepack"
                            ></decimal-input>
                          </v-col>
                        </v-row>
                        <v-row class="">
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >PLU Repack:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbPLURepack"
                            ></decimal-input>
                          </v-col>
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Squash Repack:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbSquashRepack"
                            ></decimal-input>
                          </v-col>
                        </v-row>
                        <v-row class="">
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Tomato Repack:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.inbTomatoRepack"
                            ></decimal-input>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header v-slot="{ open }">
                        <v-row no-gutters>
                          <v-col cols="4">
                            <template>
                              <v-icon color="success">
                                mdi-bank-transfer-out
                              </v-icon>
                            </template>
                            OUTBOUND
                          </v-col>
                          <v-col cols="8" class="text--secondary">
                            <v-fade-transition leave-absolute>
                              <span v-if="open" key="0"> Additional data </span>
                              <span v-else key="1">
                                {{ trip.location }}
                              </span>
                            </v-fade-transition>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="">
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Air bags:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.outAirBags"
                            ></decimal-input>
                          </v-col>
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Poly bags:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.outPolyBags"
                            ></decimal-input>
                          </v-col>
                        </v-row>
                        <v-row class="">
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Temperatura recorder:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.outTemperatureRecorder"
                            ></decimal-input>
                          </v-col>

                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >GPS recorder:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.outGPSRecorder"
                            ></decimal-input>
                          </v-col>
                        </v-row>
                        <v-row class="">
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Pallet Movement:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.outPalletMovement"
                            ></decimal-input>
                          </v-col>

                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left">Seal:</v-subheader>
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.outSeal"
                            ></decimal-input>
                          </v-col>
                        </v-row>
                        <v-row class="">
                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left"
                              >Overtime:</v-subheader
                            >
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.outOvertime"
                            ></decimal-input>
                          </v-col>

                          <v-col cols="12" xs="12" md="2">
                            <v-subheader class="label-left">PTI:</v-subheader>
                          </v-col>
                          <v-col cols="12" xs="12" md="4">
                            <decimal-input
                              :min="0"
                              :allow_negative="false"
                              v-model="form.outPTI"
                            ></decimal-input>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <subtitle-section>Contacts/Contactos</subtitle-section>
          <v-form id="formContacts">
            <v-row>
              <v-col cols="12" md="2">
                <v-subheader class="label-left">Name/Nombre:</v-subheader>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  solo
                  class="right-input"
                  v-model.trim="formContacts.description"
                  maxlength="30"
                  required
                  @input="$v.formContacts.description.$touch()"
                  @blur="$v.formContacts.description.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-subheader class="label-left"
                  >Phone Number/Número Teléfono:</v-subheader
                >
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  solo
                  class="right-input"
                  v-mask="mask"
                  v-model.trim="formContacts.number"
                  v-on:keypress="isNumber"
                  maxlength="19"
                  required
                  placeholder="(+1) 555 999 77 45"
                  @input="$v.formContacts.number.$touch()"
                  @blur="$v.formContacts.number.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="2">
                <v-subheader class="label-left"
                  >E-Mail/Correo Electrónico:</v-subheader
                >
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  solo
                  class="right-input"
                  v-model.trim="formContacts.email"
                  maxlength="30"
                  required
                  @input="$v.formContacts.email.$touch()"
                  @blur="$v.formContacts.email.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="1">
                <v-btn
                  class="add-product"
                  color="primary"
                  :disabled="
                    !(
                      (formContacts.description.length > 0 &&
                        formContacts.email.length > 0 &&
                        !$v.formContacts.email.$invalid &&
                        formContacts.number.length == 0) ||
                      (formContacts.description.length > 0 &&
                        formContacts.number.length > 0 &&
                        formContacts.email.length == 0) ||
                      (formContacts.description.length > 0 &&
                        formContacts.email.length > 0 &&
                        !$v.formContacts.email.$invalid &&
                        formContacts.number.length > 0)
                    )
                  "
                  @click="addContact"
                  small
                >
                  <v-icon dark>mdi-plus-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-data-table
            :headers="headers"
            :items="contacts"
            hide-default-header
            hide-default-footer
            class="elevation-1 tableContacts"
          >
            <template v-slot:item.actions="{ item }">
              <td>
                <v-icon small @click="askDeleteItem(item)"> mdi-delete </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="align-center justify-center">
          <v-btn
            class="action"
            color="blue"
            v-on:click.prevent="save"
            :disabled="$v.form.$invalid"
            :loading="isLoading"
          >
            {{ button }}
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
          <v-btn @click="goBack" color="red">
            Back/Regresar
            <v-icon right>mdi-cancel</v-icon>
          </v-btn>
        </v-card-actions>
      </material-card>
    </v-flex>

    <v-dialog v-model="showAskDeleteContact" persistent max-width="400">
      <v-card>
        <v-card-title class="headline"
          >Delete contact?/Eliminar contacto?</v-card-title
        >
        <v-card-text>{{
          askDeleteContact.description + " / " + askDeleteContact.number
        }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="action" color="blue" v-on:click.prevent="deleteItem">
            Agree/Acepto
            <v-icon right>mdi-check-bold</v-icon>
          </v-btn>
          <v-btn @click="showAskDeleteContact = false" color="red">
            No
            <v-icon right>mdi-cancel</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import companies from "../../services/api/companies";
import SubtitleSection from "../../components/SubtitleSection";
import DecimalInput from "../../components/fields/DecimalInput.vue";

export default {
  watch: {
    "formContacts.number": {
      handler() {
        if (this.formContacts.number.length == 18) {
          this.mask = "(+#) ### ### ## ##";
        } else if (this.formContacts.number.length >= 19) {
          this.mask = "(+##) ### ### ## ##";
        }
      },
      deep: true,
    },
  },
  name: "company",
  components: {
    SubtitleSection,
    MaterialCard: () => import("@/components/base/MaterialCard"),
    DecimalInput: () => import("@/components/fields/DecimalInput"),
  },
  data() {
    return {
      form: {
        idCompany: 0,
        id: "",
        name: "",
        contacts: [],
        inbUnloadedPallets: 0,
        inbPalletsRestack: 0,
        inbQcInspection: 0,
        inbDamagedBoxes: 0,
        inbDamagedPallets: 0,
        outAirBags: 0,
        outPolyBags: 0,
        outTemperatureRecorder: 0,
        inbPalletsIn: 0,
        inbPTI: 0,
        inbRepackIn: 0,
        inbPalletReplacement: 0,
        inbOvertime: 0,
        inbSundayOt: 0,
        inbCucumberRepack: 0,
        inbPLURepack: 0,
        inbSquashRepack: 0,
        inbTomatoRepack: 0,
        outGPSRecorder: 0,
        outPalletMovement: 0,
        outSeal: 0,
        outOvertime: 0,
        outPTI: 0,
      },
      formContacts: {
        keyx: 0,
        description: "",
        number: "",
        email: "",
      },
      isCreate: true,
      headers: [
        {
          text: "Dessert (100g serving)",
          align: "start",
          value: "description",
        },
        { text: "Phone Number", value: "number" },
        { text: "E-Mail", value: "email" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      contacts: [],
      showAskDeleteContact: false,
      askDeleteContact: {
        description: "",
        number: "",
      },
      trip: {
        name: "",
        location: null,
        start: null,
        end: null,
      },
      mask: "(+#) ### ### ## ##",
    };
  },
  validations: {
    form: {
      id: {
        required,
      },
      name: {
        required,
      },
    },
    formContacts: {
      description: {
        required,
      },
      number: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },
  async created() {
    this.isCreate = this.type == "new";

    if (!this.isCreate) {
      const response = await companies.getById(this.$route.params.idCompany);
      this.form = response.data.data;
      this.contacts = response.data.contacts;
    }
  },
  methods: {
    ...mapActions(["login"]),
    async save() {
      // ES NECESARIO CONVERTIR LA INFO ANTES DE ENVIARLA
      this.form.contacts = this.contacts;
      let data = Object.assign({}, this.form);
      data.qaFormat = JSON.stringify(this.form.qaFormat);
      try {
        const OResponse = await companies.saveEdit(data);
        if (!OResponse.body.data.bOk) {
          this.$toast.error(OResponse.body.data.errorMensaje);
        } else {
          this.$toast.success("Saved/Guardado");
          this.$router.push({
            name: "companiesList",
          });
        }
      } catch (e) {
        console.error(e);
        this.$toast.error("There was an error saving/Hubo un error al guardar");
      }
    },
    deleteItem() {
      var description = this.askDeleteContact.description;
      var number = this.askDeleteContact.number;

      var i = 0;
      this.contacts.forEach(function (x, index) {
        if (x.description === description && x.number === number) {
          i = index;
        }
      });
      this.contacts.splice(i, 1);
      this.showAskDeleteContact = false;
    },
    askDeleteItem(index) {
      this.showAskDeleteContact = true;
      this.askDeleteContact.description = index.description;
      this.askDeleteContact.number = index.number;
    },

    addContact() {
      const newVal = {
        description: this.formContacts.description,
        number: this.formContacts.number,
        email: this.formContacts.email,
      };

      console.log(newVal);
      this.contacts.push(newVal);

      this.formContacts.description = "";
      this.formContacts.number = "";
      this.formContacts.email = "";
    },
    goBack() {
      this.$router.push({
        name: "companiesList",
      });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  props: {
    type: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["isLoading"]),
    title() {
      return this.isCreate
        ? "NEW COMPANY/COMPA\u00d1IA NUEVA"
        : "EDIT COMPANY/EDITAR COMPA\u00d1IA";
    },
    button() {
      return this.isCreate ? "Create/Crear" : "Edit/Editar";
    },
    idErrors() {
      const errors = [];
      if (!this.$v.form.id.$dirty) return errors;
      !this.$v.form.id.required && errors.push("Required/Requerido");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.form.name.$dirty) return errors;
      !this.$v.form.name.required && errors.push("Required/Requerido");
      return errors;
    },
  },
};
</script>




<style scoped>
.add-product {
  margin: 0;
  padding: 0;
  top: 5px;
  left: 20px;
}
</style>